import './src/styles/global.css';
import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

export const wrapPageElement = ({ element }: any) => {
    const key: any = process.env.GOOGLE_RECAPCTHA_KEY;

    return (
        <GoogleReCaptchaProvider reCaptchaKey={key}>
            {element}
        </GoogleReCaptchaProvider>
    )
}