module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-34M6XDXWHB"],"pluginConfig":{"head":true,"respectDNT":true,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{"anonymize_ip":true,"cookie_expires":30}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"50b01ddecb10c6627cfe4e8c5bdc8538"},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","tr"],"defaultLanguage":"en","redirect":false,"siteUrl":"https://procenne.com","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang?/404","getLanguageFromPath":false},{"matchPath":"/:lang?/solutions/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/resources/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/resources/:tag?/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/blog/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/blog/:tag?/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/news/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/awards/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/events/:uid","getLanguageFromPath":true}]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
